// Import all the third-party libraries that are used in the application.
import "htmx.org";
window.htmx = htmx;

import DataTable from "datatables.net-bs5";
window.DataTable = DataTable;

// Inject the third-party libraries into the global window object.
import "./window-imports";

// Import the htmx extensions.
import "htmx.org/dist/ext/sse";
import "htmx.org/dist/ext/debug";
